import { Helmet } from "react-helmet";
import { useAppConext } from "../context/AppContext";

const PageMeta = (props) => {
    const { title, description = null } = props;
    const { branding, config } = useAppConext();
    return (
        <Helmet>
            <title>
                {title} {(branding?.brandName) ? ` | ${config?.tagline || branding.brandName}` : ''}
            </title>
            {(branding?.favicon) && (
                <link rel="shortcut icon" href={branding.favicon} />
            )}
            {(description !== null) && (
                <meta name="description" content={description} />
            )}
        </Helmet>
    );
};

export default PageMeta;